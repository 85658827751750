.container-iphone-and-text{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1em;
  row-gap: 40px;
}

  .info-text{
    /*padding-left: 200px;*/
    max-width: 500px;
    align-self: center;     
    /*
    padding-right: 100px;
    padding-top: 50px;
    */
  }
  .info-text p{
    align-items: center;
    padding-left: 70px;
  }
  .info-text h1{
    padding-left: 70px;
  }

  .outer-container {
    padding-top: 50px;
    padding-top: 50px;
    width: 100%;
  }



  .zoom-container {
    transition: transform 0.5s ease-in-out;
    max-width: 200px; /* Set a smaller max width */
    margin: 0 auto; /* Center the image horizontally */
  }
  
  .zoomed {
    transform: scale(1.2); /* Adjust the scale factor as needed */
  }
  
  .zoom-image {
    width: 100%;
    height: auto;
    display: block;
  }


  /* Mobile Styles */
  @media screen and (max-width: 1000px) {
    .classname {
      flex-direction: column;
    }
    .outer-container {
      padding-top: 0px;
    }
    .info-text p{
      padding-left: 0px;
    }
  }

  