.appcard {
  padding: 20px;
}

.appcard-container {
}

.apps-header {
  padding-top: 32px;
}
