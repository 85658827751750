.navbar {
  background-color: #111931 !important;
}

.navbar .nav-link {
  color: white !important;
  text-decoration: none;
}

.navbar .nav-link:hover {
  color: #ccc !important;
}

.navbar-brand img {
  margin-left: 40px; /* Move the logo a bit away from the left side */
  object-fit: cover; /* Ensures the logo crops around its container */
  width: 70px; /* Adjust width to crop the edges */
  height: 70px; /* Adjust height to crop the edges */
  border-radius: 5px; /* Optional: Add rounded corners if desired */
}
.navbar .ml-auto {
  margin-right: 40px; /* Move the links a bit away from the right side */
}
