.slider-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 30px;
  }
  
  .slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .slide-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    .slide-image {
      max-height: 400px;
    }
  }
  
  @media (max-width: 480px) {
    .slide-image {
      max-height: 300px;
    }
  }
  