
.info {
    padding-top: 100px;
    padding-right: 60px;
    padding-left: 60px; /* Add more space on the left */
  }

  .info h1 {
    margin-left: 40px;
  }
  
  /* Add some space on the left for the paragraph and list */
  .info p,
  .info ul {
    margin-left: 40px; /* Add space on the left */
  }
