/* src/components/Footer.css */

.footer {
  height: 200px;
  padding: 20px;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #111931; /* Set the background color */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
  /*position: fixed;*/
  margin-top: auto;
}

.icon-container {
  margin-top: -20px; /* Adjust the margin to move icons further up */
  display: flex;
  align-items: center;
  flex-direction: row; /* Stack items vertically */
  justify-content: center; /* Horizontally center the icons */
}

.icon {
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 15px;
  transition: transform 0.2s;
}
.icon svg {
  color: #3fd0ef; /* or any other color */
}

.icon:hover {
  transform: scale(1.1);
}

.footer-line {
  width: 80%;
  border: 0;
  border-top: 1px solid white;
  margin: 10px 0;
}

.copyright {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}
