.item{
    padding-bottom: 30px;
}

.carousel-wrapper {
    max-width: 600px; /* Adjust max-width as needed */
    margin: 0 auto;   /* Center the carousel */
  }
  
.slide-image {
    height: 1000px; /* Adjust image height */
    width: 100%; /* Ensure it takes full width of the carousel */
    padding-bottom: 50px;
  }