.card-img-top {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
}

.card-img-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-no-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coming-soon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg); /* Center the text and rotate */
  color: #333; /* Dark grey text */
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  cursor: default; /* Set cursor to default */
  white-space: nowrap; /* Prevent text from wrapping */
}
